import React, {useEffect} from "react";
import {requestLog} from "../Util";
import {useParams} from "react-router-dom";
import Tab from "@jetbrains/ring-ui-built/components/tabs/tab";
import Col from "@jetbrains/ring-ui-built/components/grid/col";
import Row from "@jetbrains/ring-ui-built/components/grid/row";
import ProgressBar from "@jetbrains/ring-ui-built/components/progress-bar/progress-bar";
import Markdown from "@jetbrains/ring-ui-built/components/markdown/markdown";
import {Tabs} from "@jetbrains/ring-ui-built/components/tabs/tabs";
import {Grid} from "@jetbrains/ring-ui-built/components/grid/grid";
import MarkdownIt from "markdown-it";

export const LogPage = () => {
  const {reportType, projectKey, repositoryName} = useParams()

  return (
    <Grid>
      <Row center="xs">
        <Col xs={8}>
          <Tabs selected={'0'}>
            <Tab
              id="0"
              key="0"
              title={`${projectKey}/${repositoryName}/${reportType.toLowerCase()}`}
            >
              <Log projectKey={projectKey} repositoryName={repositoryName} reportType={reportType}/>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Grid>
  );
};

const Log = ({projectKey, repositoryName, reportType}) => {
  const [content, setContent] = React.useState('')
  const [progress, setProgress] = React.useState(0)

  const updateLog = () => {
    requestLog({
      projectKey: projectKey,
      repositoryName: repositoryName,
      reportType: reportType,
    }, (data) => {
      setProgress((progress) => progress > 1 ? 0 : progress + 0.1)
      setContent(data)
    })
  }

  useEffect(() => {
    updateLog()
    const interval = setInterval(() => {
      updateLog()
    }, 5000)
    return () => clearInterval(interval);
  }, [])

  const markdownIt = new MarkdownIt('commonmark', {
    html: false,
  });

  const renderedLogs = markdownIt.render(
    `\`\`\`
  ${content}
  `
  )

  return (
    <Row start="xs">
      <Col xs={12} className="cell">
        <ProgressBar label="Progress" value={progress}/>
        <pre style={{minHeight: "75vh", maxHeight: "75vh"}}>
          <Markdown>
            <div dangerouslySetInnerHTML={{
              __html: renderedLogs
            }}/>
          </Markdown>
          </pre>
      </Col>
    </Row>
  )
}