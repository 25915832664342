import {
  COUPLING_TAG_VALUES,
  DEPENDENCY_TAG_VALUES,
  FREQUENCY_TAG_VALUES,
  PATH_TAG_VALUES
} from "./features/coupling-table/const";

export const couplingFilterData = [
  {
    key: '1',
    label: COUPLING_TAG_VALUES.CRITICAL,
  },
  {
    key: '2',
    label: COUPLING_TAG_VALUES.HIGH,
  },
  {
    key: '3',
    label: COUPLING_TAG_VALUES.MEDIUM,
  },
  {
    key: '4',
    label: COUPLING_TAG_VALUES.LOW,
  },
  {
    key: '5',
    label: COUPLING_TAG_VALUES.MINOR,
  }
]

export const dependencyFilterData = [
  {
    key: '1',
    label: DEPENDENCY_TAG_VALUES.CODEPENDENT
  },
  {
    key: '2',
    label: DEPENDENCY_TAG_VALUES.ONE_WAY_DEPENDENT
  }
]

export const frequencyFilterData = [
  {
    key: '1',
    label: FREQUENCY_TAG_VALUES.EXTREMELY_FREQUENT
  },
  {
    key: '2',
    label: FREQUENCY_TAG_VALUES.FREQUENT
  },
  {
    key: '3',
    label: FREQUENCY_TAG_VALUES.REGULARLY
  },
]

export const pathFilterData = [
  {
    key: '1',
    label: PATH_TAG_VALUES.DIFFERENT_MODULES
  },
  {
    key: '2',
    label: PATH_TAG_VALUES.CLOSE_FILES
  },
]
