import {React} from "react";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import Island from "@jetbrains/ring-ui-built/components/island/island";
import {Content} from "@jetbrains/ring-ui-built/components/island/island";
import Header from "@jetbrains/ring-ui-built/components/island/header";
import Markdown from "@jetbrains/ring-ui-built/components/markdown/markdown";
import MarkdownIt from "markdown-it";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function ComplexityChart({values, labels}) {
  const data = {
    datasets: [
      {
        label: 'Entropy',
        data: values,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3
      },
    ],
    labels: labels
  };
  const options = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      title: {
        display: false,
        text: 'Complexity',
      },
    },
    scales: {
      y: {
        bounds: 'data',
        sampleSize: 10,
        autoSkip: false
      },
      x: {
        autoSkip: false
      }
    }
  };

  const markdownIt = new MarkdownIt('commonmark', {
    html: false,
  });

  const renderedComplexityDescription = markdownIt.render(
    `
  This chart represents the complexity of code changes in the repository over fixed periods of time. 
    
    
  The main reasons of spikes in entropy:
  * • developers working on too many aspects of the system concurrently
  * • complexity of the code
  * • refactoring many parts of the system
  `
  )

  return <Island>
    <Header border>
      Complexity code changes
    </Header>
    <Content>
      <div style={{marginBottom: "15px"}}>
        <Markdown>
          <div dangerouslySetInnerHTML={{
            __html: renderedComplexityDescription
          }}/>
        </Markdown>
      </div>
      <Island><Content>
        <Line options={options} data={data}/>
      </Content></Island>
    </Content>
  </Island>
}