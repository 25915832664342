import React from "react";
import {Col, Row} from "@jetbrains/ring-ui-built/components/grid/grid";
import Text from "@jetbrains/ring-ui-built/components/text/text";
import {TAG_TYPES} from "./const";
import {capitalizeFirstLetter, commonPath, getConclusionFromPair, getInsightFromPair} from "./utils";
import {TreeDependencySchema} from "./components/tree-dependency-schema/tree-dependency-schema";
import Icon from "@jetbrains/ring-ui-built/components/icon/icon";
import lamp from "@jetbrains/icons/lamp";
import info from "@jetbrains/icons/info";
import CollapseContent from "@jetbrains/ring-ui-built/components/collapse/collapse-content";
import MarkdownIt from "markdown-it";
import Markdown from "@jetbrains/ring-ui-built/components/markdown/markdown";

export const CouplingCollapsedRow = ({pair}) => {
  const filenameBeauty1 = "./" + pair.filename1
  const filenameBeauty2 = "./" + pair.filename2

  let prefix = commonPath(filenameBeauty1, filenameBeauty2)
  const differentPathForFile1 = filenameBeauty1.substr(prefix.length, filenameBeauty1.length)
  const differentPathForFile2 = filenameBeauty2.substr(prefix.length, filenameBeauty2.length)

  prefix = prefix.substr(1, prefix.length)

  const percentFile2IfFile1Changes = Math.round(pair.changeProbabilityOfFile2IfFile1Changes * 10000) / 100
  const percentFile1IfFile2Changes = Math.round(pair.changeProbabilityOfFile1IfFile2Changes * 10000) / 100

  const markdownIt = new MarkdownIt('commonmark', {
    html: false,
  });

  return (
    <CollapseContent>
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div>
            <Text size="m" info>Issue explanation</Text>
          </div>
          {
            pair.tags.map((tag) => {
              const renderedDescription = markdownIt.render(tag.tagComment)

              return (tag.tagType !== TAG_TYPES.COUPLING && <div>
                <Text size="m" bold>{capitalizeFirstLetter(tag.tagValue)}: </Text>
                <Text size="m">
                  <Markdown>
                    <div dangerouslySetInnerHTML={{
                    __html: renderedDescription
                    }}/>
                  </Markdown>
                </Text>
              </div>)
            }
            )
          }
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="coupling-table__collapsed-dependency-schema">
            <div>
              <Text size="m" info>Dependency schema</Text>
            </div>
            <TreeDependencySchema commonPath={prefix}
                                  topFile={differentPathForFile1}
                                  bottomFile={differentPathForFile2}
                                  topToBottomArrowValue={percentFile2IfFile1Changes}
                                  bottomToTopArrowValue={percentFile1IfFile2Changes}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="coupling-table__collapsed-conclusion_main">
            <Icon glyph={lamp} className="coupling-table__collapsed-insight-icon"/>
            <Text size="m">{getInsightFromPair(pair)}</Text>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="coupling-table__collapsed-conclusion_main">
            <Icon glyph={info} className="coupling-table__collapsed-conclusion-icon"/>
            <Text size="m">{getConclusionFromPair(pair)}</Text>
          </div>
        </Col>
      </Row>
    </CollapseContent>
  )
}