import React from "react";
import Island, {Content, Header} from "@jetbrains/ring-ui-built/components/island/island";
import TagsList from "@jetbrains/ring-ui-built/components/tags-list/tags-list";
import Alert from "@jetbrains/ring-ui-built/components/alert/alert";
import {useNavigate} from "react-router-dom";
import {rerunTask, scheduleTask} from "../Util";
import Markdown from "@jetbrains/ring-ui-built/components/markdown/markdown";
import {ReportType} from "./VisualizationView";
import ButtonGroup from "@jetbrains/ring-ui-built/components/button-group/button-group";
import {ControlsHeight, ControlsHeightContext} from "@jetbrains/ring-ui-built/components/global/controls-height";
import projectIcon from '@jetbrains/icons/project-14px';
import averageIcon from '@jetbrains/icons/average';
import downloadIcon from '@jetbrains/icons/download';
import hourglassIcon from '@jetbrains/icons/hourglass';
import userIcon from '@jetbrains/icons/user';
import puzzleIcon from '@jetbrains/icons/puzzle';
import updateIcon from '@jetbrains/icons/update';
import Button from "@jetbrains/ring-ui-built/components/button/button";
import MarkdownIt from 'markdown-it';
import Row from "@jetbrains/ring-ui-built/components/grid/row";
import {Grid} from "@jetbrains/ring-ui-built/components/grid/grid";
import Col from "@jetbrains/ring-ui-built/components/grid/col";
import Tooltip from "@jetbrains/ring-ui-built/components/tooltip/tooltip";

export const RUNNING = "RUNNING"
export const READY = "READY"
export const NEED_LOAD = "NEED_LOAD"

export const ReportStatus = {
  pending: "PENDING",
  scheduled: "SCHEDULED",
  running: "RUNNING",
  done: "DONE", // Statuses "DONE" and "READY" are the same, the only difference is
  ready: "READY", // "DONE" is used at reports endpoint (almost everywhere), "READY" is used only in events endpoint
  failed: "FAILED",
}

function SpaceProjectView(props) {
  const projectKey = props.spaceProject.info.key
  const navigate = useNavigate();

  const projectName = props.spaceProject.info.name

  const rerun = (projectName, projectKey, repositoryName, reportType, reportId, alert) => {
    rerunTask(projectKey, repositoryName, reportType, reportId, () => {
      props.onCloseAlertClick(alert)
      props.createAlertPR(
        Alert.Type.MESSAGE,
        `Task for ${repositoryName} ${reportType} is scheduled. Wait and refresh page for results.`,
        projectName,
        repositoryName
      )

      const afterLoad = props.afterLoad
      if (afterLoad) {
        afterLoad()
      }
    })
  }

  const schedule = (projectName, projectKey, repositoryName, reportType, alert) => {
    scheduleTask(projectKey, repositoryName, reportType, () => {
      props.onCloseAlertClick(alert)
      props.createAlertPR(
        Alert.Type.MESSAGE,
        `Task for ${repositoryName} ${reportType} is scheduled. Wait and refresh page for results.`,
        projectName, repositoryName
      )

      const afterLoad = props.afterLoad
      if (afterLoad) {
        afterLoad()
      }
    })
  }


  const reportButton = (repositoryName, report, text) => {
    const type = report.type
    const status = report.status

    let args = {}
    switch (status) {
      case undefined:
        args = {
          ...args, icon: downloadIcon, primary: false,
          onMouseDown: () => {
            props.setRepositoryStatus(projectName, repositoryName, type, ReportStatus.scheduled)
            const alert = props.createAlert(Alert.Type.LOADING, `Sending request "${repositoryName}" of project ${projectName}`)
            schedule(projectName, projectKey, repositoryName, type, alert)
          }
        }
        break
      case ReportStatus.failed:
        args = {
          ...args,
          danger: true,
          onMouseDown: () => {
            props.setRepositoryStatus(projectName, repositoryName, type, ReportStatus.scheduled)
            const alert = props.createAlert(Alert.Type.LOADING, `Sending request "${repositoryName}" of project ${projectName}`)
            console.log(report)
            rerun(projectName, projectKey, repositoryName, type, report.id, alert)
          },
          icon: updateIcon
        }
        break
      case ReportStatus.done:
      case ReportStatus.ready:
        args = {
          ...args, primary: true,
          onMouseDown: () => {
            navigate(`/vis/${projectKey}/${repositoryName}/${type}`)
          }
        }
        switch (type) {
          case ReportType.bf:
            args = {...args, icon: projectIcon}
            break
          case ReportType.complexity:
            args = {...args, icon: averageIcon}
            break
          case ReportType.contributors:
            args = {...args, icon: userIcon}
            break
          case ReportType.coupling:
            args = {...args, icon: puzzleIcon}
            break
        }
        break
      default:
        args = {...args, icon: hourglassIcon, disabled: true}
    }
    if (repositoryName === 'ultimate' && type === ReportType.bf) {
      return <Tooltip title="Result is too large, currently not supported">
        <Button {...args} disabled>
          {text}
        </Button>
      </Tooltip>
    }
    return <Button {...args}>
      {text}
    </Button>
  }

  const findReport = (repositoryInfo, type) => {
    const report = repositoryInfo.reports.find((it) => it.type === type)
    if (report === undefined) {
      return {type: type, status: undefined}
    }
    return report
  }

  const reportsButtonGroup = (repositoryInfo) => {
    const repositoryName = repositoryInfo.name
    return <ControlsHeightContext.Provider value={ControlsHeight.S}>
      <ButtonGroup>
        <span className="hasUpdates">
          {reportButton(repositoryName, findReport(repositoryInfo, ReportType.coupling), "coupling")}
        </span>
        {reportButton(repositoryName, findReport(repositoryInfo, ReportType.bf), "bus-factor")}
      </ButtonGroup>
    </ControlsHeightContext.Provider>
  }

  const markdownIt = new MarkdownIt('commonmark', {
    html: false,
  });

  const renderedSpaceProjectDescription = markdownIt.render(props.spaceProject.info.description)

  return <div style={{marginBottom: "32px"}}>
    <Island>
      <Header border>
        {props.spaceProject.info.name}
      </Header>

      <Content fade>
        <TagsList
          tags={props.spaceProject.info.tags.map(it => {
            return {label: it, readOnly: true, key: it}
          })}
        />

        <Markdown>
          <div dangerouslySetInnerHTML={{
            __html: renderedSpaceProjectDescription
          }} />
        </Markdown>

        <div>
          <Grid style={{maxHeight: "400px", overflowY: "auto", marginTop: "16px", paddingLeft: "0"}}>
            {props.spaceProject.repositories.map((repositoryInfo) =>
                <div className="highlight-me">
                  <Row start="xs">
                      <Col xs={6} className="cell">
                        {repositoryInfo.name}
                      </Col>
                      <Col xs={6} className="cell">
                        <div style={{textAlign: "right"}}>
                          {reportsButtonGroup(repositoryInfo)}
                        </div>
                      </Col>
                  </Row>
                  {repositoryInfo.description &&
                      <Row>
                        <Col xs={12} className="details">
                          <Row start="xs">
                            <Col xs={12} className="details">
                              {repositoryInfo.description}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                  }
                </div>
            )}
          </Grid>
        </div>
      </Content>
    </Island>
  </div>;
}

export default SpaceProjectView;

