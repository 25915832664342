import React from "react"
import {Grid, Row, Col} from "@jetbrains/ring-ui-built/components/grid/grid";
import Panel from "@jetbrains/ring-ui-built/components/panel/panel";
import {TAG_TYPES} from "./const";
import Island from "@jetbrains/ring-ui-built/components/island/island";
import Text from "@jetbrains/ring-ui-built/components/text/text";
import {CouplingRow} from "./coupling-row";
import {CouplingCollapsedRow} from "./coupling-collapsed-row";
import {Collapse} from "@jetbrains/ring-ui-built/components/collapse/collapse";


export const CouplingTable =
  ({
     projectKey,
     repositoryName,
     changedPairs,
     selectedCouplingFilter,
     selectedDependencyFilter,
     selectedFrequencyFilter,
     selectedPathFilter
   }) => {
    const allRules = [
      {
        ruleOptions: selectedCouplingFilter,
        ruleName: TAG_TYPES.COUPLING
      },
      {
        ruleOptions: selectedDependencyFilter,
        ruleName: TAG_TYPES.DEPENDENCY
      },
      {
        ruleOptions: selectedFrequencyFilter,
        ruleName: TAG_TYPES.FREQUENCY
      },
      {
        ruleOptions: selectedPathFilter,
        ruleName: TAG_TYPES.PATH
      },
    ]

    const selectedPairs =
      changedPairs.filter((pair) =>
        allRules.every(({ruleOptions, ruleName}) =>
          ruleOptions.length === 0 || ruleOptions.some((ruleOption) => pair.tags.some(
            ({tagType, tagValue}) => ruleOption.label === tagValue && ruleName === tagType))
        ))

    return (
      <Island>
        <Grid className="coupling-table">
          <Panel className="coupling-table__header">
            <Row>
              <Col xs={12} sm={1} md={1} lg={1}>
                <Text size={Text.Size.S} info>Coupling score</Text>
              </Col>

              <Col xs={12} sm={4} md={4} lg={4}>
                <Text size={Text.Size.S} info>Pair of files</Text>
              </Col>

              <Col xs={12} sm={6} md={6} lg={6} className="coupling-table__header_end">
                <Text size={Text.Size.S} info>Tags</Text>
              </Col>
            </Row>
          </Panel>
          {selectedPairs.map((pair) =>
            (<Collapse>
                <Panel className="coupling-table__row">
                  <CouplingRow pair={pair} repositoryName={repositoryName}
                               projectKey={projectKey}/>
                  <CouplingCollapsedRow pair={pair}/>
                </Panel>
              </Collapse>
            )
          )}
        </Grid>
      </Island>);
  }
