import React from "react";
import Select from "@jetbrains/ring-ui-built/components/select/select";

export const MultipleChoiceFilter = ({selected, setSelected, data, innerLabel, upperLabel}) => {
  const handleSelect = (option) => {
    setSelected((optionsList) => [...optionsList, option])
  }

  const handleDeselect = (option) => {
    setSelected((optionsList) => optionsList.filter((el) => el.label !== option.label))
  }

  const handleReset = () => {
    setSelected([])
  }

  return <Select selectedLabel={upperLabel} label={innerLabel} filter className="coupling-top-panel__filter"
                 selected={selected} onSelect={handleSelect} onDeselect={handleDeselect} onReset={handleReset}
                 data={data}
                 multiple={{selectAll: true}}/>
}
