import React, {useState} from "react"
import {CouplingTopPanel} from "../features/coupling-top-panel/coupling-top-panel";
import {couplingFilterData, dependencyFilterData, frequencyFilterData, pathFilterData} from "../const";
import {CouplingTable} from "../features/coupling-table";


export const CouplingPage = ({changedPairs, projectKey, repositoryName}) => {
  const [selectedCouplingFilter, setSelectedCouplingFilter] = useState([])
  const [selectedDependencyFilter, setSelectedDependencyFilter] = useState([])
  const [selectedFrequencyFilter, setSelectedFrequencyFilter] = useState([])
  const [selectedPathFilter, setSelectedPathFilter] = useState([])

  return (<div className="coupling-page">
    <CouplingTopPanel
      projectKey={projectKey}
      repositoryName={repositoryName}

      selectedCouplingFilter={selectedCouplingFilter} setSelectedCouplingFilter={setSelectedCouplingFilter}
      couplingFilterData={couplingFilterData}

      selectedDependencyFilter={selectedDependencyFilter} setSelectedDependencyFilter={setSelectedDependencyFilter}
      dependencyFilterData={dependencyFilterData}

      selectedFrequencyFilter={selectedFrequencyFilter} setSelectedFrequencyFilter={setSelectedFrequencyFilter}
      frequencyFilterData={frequencyFilterData}

      selectedPathFilter={selectedPathFilter} setSelectedPathFilter={setSelectedPathFilter}
      pathFilterData={pathFilterData}
    />
    <CouplingTable
      projectKey={projectKey}
      repositoryName={repositoryName}
      changedPairs={changedPairs}
      selectedCouplingFilter={selectedCouplingFilter}
      selectedDependencyFilter={selectedDependencyFilter}
      selectedFrequencyFilter={selectedFrequencyFilter}
      selectedPathFilter={selectedPathFilter}
    />
  </div>)
}
