import React from "react";
import {
  getCouplingLevelFromPair,
  getSpaceRepositoryLinkToFile,
  getTagColorByTagTypeAndValue
} from "./utils";
import {Col, Row} from "@jetbrains/ring-ui-built/components/grid/grid";
import {CouplingBadge} from "./components/badge/coupling-badge";
import Link from "@jetbrains/ring-ui-built/components/link/link";
import Text from "@jetbrains/ring-ui-built/components/text/text";
import {TAG_TYPES} from "./const";
import Tooltip from "@jetbrains/ring-ui-built/components/tooltip/tooltip";
import Tag from "@jetbrains/ring-ui-built/components/tag/tag";
import CollapseControl from "@jetbrains/ring-ui-built/components/collapse/collapse-control";
import Icon from "@jetbrains/ring-ui-built/components/icon/icon";
import chevronDownIcon from "@jetbrains/icons/chevron-down";
import chevronUpIcon from "@jetbrains/icons/chevron-up";
import MarkdownIt from "markdown-it";
import Markdown from "@jetbrains/ring-ui-built/components/markdown/markdown";

export const CouplingRow = ({pair, projectKey, repositoryName}) => {
  const markdownIt = new MarkdownIt('commonmark', {
    html: false,
  });

  return (
    <Row start="xs">
      <Col xs={12} sm={1} md={1} lg={1}>
        <div className="coupling-table__item_rowed_centered">
          {getCouplingLevelFromPair(pair) && <CouplingBadge tagValue={getCouplingLevelFromPair(pair)}/>}
        </div>
      </Col>

      <Col xs={12} sm={4} md={4} lg={4}>
        <div className="coupling-table__item_columned">
          <Link href={getSpaceRepositoryLinkToFile(projectKey, repositoryName, pair.filename1)}
                active>
            <Text size="m">{pair.shortFilename1}</Text>
          </Link>
          <Link href={getSpaceRepositoryLinkToFile(projectKey, repositoryName, pair.filename2)}
                active>
            <Text size="m">{pair.shortFilename2}</Text>
          </Link>
        </div>
      </Col>

      <Col xs={12} sm={6} md={6} lg={6}>
        <div className="coupling-table__item_rowed">
          {pair.tags.map((tag) =>
              tag.tagType !== TAG_TYPES.COUPLING && (
                <Tooltip title={
                  <Markdown>
                    <div dangerouslySetInnerHTML={{
                      __html: markdownIt.render(tag.tagComment)
                    }}/>
                  </Markdown>
                } long>
                  <Tag
                    backgroundColor={getTagColorByTagTypeAndValue(tag).backgroundColor}
                    textColor={getTagColorByTagTypeAndValue(tag).textColor}
                    className="coupling-table__badge"
                    readOnly>
                    {tag.tagValue}
                  </Tag>
                </Tooltip>
              )
          )}
        </div>
      </Col>

      <Col xs={12} sm={1} md={1} lg={1}>
        <div className="coupling-table__item_rowed_centered coupling-table__item-chevron">
          <CollapseControl>
            {(collapsed) => collapsed ? <Icon glyph={chevronDownIcon}/> : <Icon glyph={chevronUpIcon}/>}
          </CollapseControl>
        </div>
      </Col>
    </Row>
  )
}