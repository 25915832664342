import React from 'react'
import Text from "@jetbrains/ring-ui-built/components/text/text";
import {ShortArrowGray} from "./short-arrow-gray";
import {ShortArrowGrayReversed} from "./short-arrow-gray-reversed";
import Tooltip from "@jetbrains/ring-ui-built/components/tooltip/tooltip";

export const TreeDependencySchema =
  ({
     commonPath,
     topFile,
     bottomFile,
     topToBottomArrowValue,
     bottomToTopArrowValue
   }) => {
    const commonPartBlock = (
      <Tooltip title="common part">
        <Text size={Text.Size.M} className="tree-dependency-schema__common-part">{commonPath}</Text>
      </Tooltip>
    )
    const fileABlock = (
      <Tooltip title="file A">
        <Text size={Text.Size.M}>{topFile}</Text>
      </Tooltip>
    )
    const fileBBlock = (
      <Tooltip title="file B">
        <Text size={Text.Size.M}>{bottomFile}</Text>
      </Tooltip>
    )

    const topToBottomArrowBlock = (
      <Tooltip title="dependence of file B on file A" className="tree-dependency-schema__numbers">
        <Text size={Text.Size.M} info>{topToBottomArrowValue}%</Text>
      </Tooltip>
    )
    const bottomToTopArrowBlock = (
      <Tooltip title="dependence of file A on file B" className="tree-dependency-schema__numbers">
        <Text size={Text.Size.M} info>{bottomToTopArrowValue}%</Text>
      </Tooltip>
    )


    if (commonPath === "" || commonPath === "." || commonPath === "./") {
      return (
        <div className="tree-dependency-schema">
          {fileABlock}
          <div className="tree-dependency-schema__top-to-bottom-area">
            {topToBottomArrowBlock}
            <ShortArrowGray/>
            <ShortArrowGrayReversed/>
            {bottomToTopArrowBlock}
          </div>
          {fileBBlock}
        </div>);
    }

    return (
      <div className="tree-dependency-schema">
        {commonPartBlock}
        <div className="tree-dependency-schema__main">
          <div className="tree-dependency-schema__sub-main">
            {fileABlock}
            <div className="tree-dependency-schema__top-to-bottom-area">
              {topToBottomArrowBlock}
              <ShortArrowGray/>
              <ShortArrowGrayReversed/>
              {bottomToTopArrowBlock}
            </div>
            {fileBBlock}
          </div>
        </div>
      </div>
    );
  }