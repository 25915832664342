export const TAG_TYPES = {
  COUPLING: "TAG_COUPLING",
  DEPENDENCY: "TAG_DEPENDENCY",
  FREQUENCY: "TAG_FREQUENCY",
  PATH: "TAG_PATH"
}

export const COUPLING_TAG_VALUES = {
    CRITICAL: "critical",
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  MINOR: "minor"
}

export const DEPENDENCY_TAG_VALUES = {
  CODEPENDENT: "codependent",
  ONE_WAY_DEPENDENT: "one way dependent"
}

export const FREQUENCY_TAG_VALUES = {
  EXTREMELY_FREQUENT: "extremely frequent",
  FREQUENT: "frequent",
  REGULARLY: "regularly",
}

export const PATH_TAG_VALUES = {
  DIFFERENT_MODULES: "different modules",
  CLOSE_FILES: "close files"
}
