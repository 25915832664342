import React from 'react';

import "@jetbrains/ring-ui-built/components/style.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";

import './index.css'
import App from "./App";
import {createRoot} from "react-dom/client";

const rootDOM = document.getElementById('root');
const root = createRoot(rootDOM);
root.render(<App />)