import React from "react";
import ErrorMessage from "@jetbrains/ring-ui-built/components/error-message/error-message";
import frownIcon from "@jetbrains/icons/frown";
import Link from "@jetbrains/ring-ui-built/components/link/link";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(`Error was caught: ${error}`)
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{margin: "auto", marginTop: "256px", marginBottom: "256px"}}>
                    <ErrorMessage icon={frownIcon} code="Something went wrong" description="Please try again later.">
                        <Link href="/">Go to the home page</Link>
                    </ErrorMessage>
                </div>
            );
        }

        return this.props.children;
    }
}
