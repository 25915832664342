import React from "react";
import {TimeoutInput} from "./TimeoutInput";
import searchIcon from '@jetbrains/icons/search';
import SpaceProjectView, {NEED_LOAD, READY, ReportStatus, RUNNING} from "./SpaceProjectView";
import {homeViewStyle, requestProjects, requestReadyProjects, requestStatusOfTasks} from "../Util";
import Alert, {Container} from "@jetbrains/ring-ui-built/components/alert/alert";

import ErrorMessage from "@jetbrains/ring-ui-built/components/error-message/error-message";
import frownIcon from '@jetbrains/icons/frown';
import {LoaderWithProgress} from "./LoaderWithProgress";
import Link from "@jetbrains/ring-ui-built/components/link/link";

const RUNNING_STATUS = "RUNNING"
const ERROR_STATUS = "ERROR"
const FINISHED_STATUS = "FINISHED"

export class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spaceProjects: [],
      inputValue: "",
      loadingFromSpace: true,
      alerts: [],
      queryValue: "",
      loadProgress: 0.0,
      intervalId: undefined,
      requestingStatus: false
    };
  }

  setRequestStatus(value) {
    this.setState({
      requestingStatus: value
    })
  }

  requestStatus() {
    requestStatusOfTasks((data) => {
      let requestingStatus = false
      for (const job of data) {
        switch (job.status) {
          case RUNNING_STATUS:
            requestingStatus = true
            break
          case ERROR_STATUS:
            this.onCloseAlertClickPRKey(Alert.Type.MESSAGE, job.projectName, job.repositoryName);
            if (job.message !== null && job.message.includes("token") ) {
              this.createAlert(Alert.Type.ERROR, <> Error while loading {job.projectName}/{job.repositoryName}. Please <Link onClick={() => window.open("https://jetbrains.team/im/thread/2MfnKR34SJlJ/9fDvb0T1wmR?message=9fFav0T1wqf&channel=2AbM7o09LJLI")}>use the solution in the link</Link> </>);
            } else {
              this.createAlert(Alert.Type.ERROR, `Internal Server Error for ${job.projectName}/${job.repositoryName} : ${job.message}`);
            }
            this.setRepositoryStatus(job.projectName, job.repositoryName, job.reportType, NEED_LOAD)
            break
          case FINISHED_STATUS:
            this.onCloseAlertClickPRKey(Alert.Type.MESSAGE, job.projectName, job.repositoryName)
            this.createAlert(Alert.Type.SUCCESS, <> Calculation for <Link href={`/vis/${job.projectKey}/${job.repositoryName}/${job.reportType}`}>{job.projectName}/{job.repositoryName}</Link> is done! </>)
            this.setRepositoryStatus(job.projectName, job.repositoryName, job.reportType, READY)
            break
        }
      }
      this.setRequestStatus(requestingStatus)
    })
  }

  componentDidMount() {
    this.requestStatus()
    const intervalId = setInterval(() => {
      if (this.state.requestingStatus) {
        this.requestStatus()
      }
    }, 5000);
    this.setState({
      intervalId: intervalId
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  setRepositoryStatus(projectName, repositoryName, type, status) {
    const data = this.state.spaceProjects
    const project = data.find(it => it.info.name === projectName)
    const repository = project.repositories.find(it => it.name === repositoryName)

    const newReport = {"type": type, "status": status}
    let reports = repository.reports.filter(it => it.type !== type)
    reports = [...reports, newReport]

    repository.reports = reports
    if ([ReportStatus.scheduled, ReportStatus.pending, ReportStatus.running].indexOf(status) > -1) {
      this.setRequestStatus(true)
    }
    this.setState({
      spaceProjects: data
    })
  }

  loaderOnRequestFromInput(inputValue) {
    let message
    if (inputValue !== "") {
      message = `Looking for "${inputValue}" in Space ...`
    } else {
      message = "Loading projects ..."
    }
    return <LoaderWithProgress message={message} loadProgress={this.state.loadProgress}/>
  }

  errorMessageOnEmptyList(inputValue) {
    let msg
    const code = "Empty projects"
    let description
    if (inputValue === "") {
      msg = `there is no ready projects on server.`
      description = "Search and load a project from Space."
    } else {
      msg = `there is no projects found with term "${inputValue}"`
      description = "Change search query."
    }

    return (
      <div style={{height: '60vh'}}>
        <ErrorMessage
          icon={frownIcon}
          code={code}
          message={msg}
          description={description}
        >
        </ErrorMessage>
      </div>
    )
  }

  trackProgress(progress) {
    this.setState({loadProgress: progress})
  }

  showProjects() {
    const inputValue = this.state.queryValue
    if (this.state.loadingFromSpace) {
      return this.loaderOnRequestFromInput(inputValue)
    }

    if (this.state.spaceProjects.length === 0) {
      return this.errorMessageOnEmptyList(inputValue)
    }

    return (this.state.spaceProjects.map(it =>
      <SpaceProjectView
        spaceProject={it}
        createAlert={(type, msg) => this.createAlert(type, msg)}
        createAlertPR={(type, msg, projectName, repositoryName) => this.createAlertPR(type, msg, projectName, repositoryName)}
        onCloseAlertClick={(alert) => this.onCloseAlertClick(alert)}
        setRepositoryStatus={(projectName, repositoryName, type, status) => this.setRepositoryStatus(projectName, repositoryName, type, status)}
      />
    ));
  }

  updateStateBeforeRequest(extra) {
    this.setState({
      ...extra,
      loadingFromSpace: true,
    })
  }

  updateStateAfterRequest(extra) {
    this.setState({
      ...extra,
      loadingFromSpace: false,
      loadProgress: 0.0
    })
  }

  setReadyProjects() {
    this.updateStateBeforeRequest()
    requestReadyProjects((response) => {
      this.updateStateAfterRequest({spaceProjects: response})
    }, (progress) => this.trackProgress(progress))
  }


  onCloseAlert(closedAlert) {
    this.setState(prevState => ({
      alerts: prevState.alerts.filter(alert => alert !== closedAlert)
    }));
  };

  onCloseAlertClick(alert) {
    const alertToClose = this.state.alerts.filter(it => alert.key === it.key)[0];
    alertToClose.isClosing = true;
    this.setState(prevState => ({
      ...prevState,
      alerts: [...prevState.alerts],
    }));
  };

  onCloseAlertClickPRKey(type, projectName, repositoryName) {
    const key = this.createPRKey(type, projectName, repositoryName)
    const alertToClose = this.state.alerts.filter(it => key === it.key)[0];
    alertToClose.isClosing = true;
    this.setState(prevState => ({
      ...prevState,
      alerts: [...prevState.alerts],
    }));
  };

  createPRKey(type, projectName, repositoryName) {
    return `${type}/${projectName}/${repositoryName}`
  }

  createAlertPR(type, message, projectName, repositoryName) {
    const alert = {
      type: type,
      key: this.createPRKey(type, projectName, repositoryName),
      message: message,
      isClosing: false
    };

    this.setState(
      {
        alerts: [alert, ...this.state.alerts]
      }
    )
    return alert;
  }

  createAlert(type, message) {
    const alert = {
      type: type,
      key: Date.now(),
      message: message,
      isClosing: false
    };

    this.setState(
      {
        alerts: [alert, ...this.state.alerts]
      }
    )
    return alert;
  };

  mainView() {
    return <div style={homeViewStyle}>
      <div style={{
        marginBottom: "8px"
      }}>
        <TimeoutInput
          icon={searchIcon}
          label={"Filter"}
          value={this.state.inputValue}
          onChange={(event) => {
            this.setState({
              inputValue: event.query
            })
          }}
          onTimeout={(event) => {
            const value = this.state.inputValue
            if (value) {
              this.updateStateBeforeRequest({queryValue: value})
              requestProjects(value, (response) => {
                this.updateStateAfterRequest({spaceProjects: response})
              }, (progress) => this.trackProgress(progress))
            } else {
              this.setReadyProjects()
            }
          }}
        />
      </div>

      {this.showProjects()}

      <Container>
        {this.state.alerts.map(alert => {
          const {message, key, ...rest} = alert;
          return (
            <Alert
              key={key}
              {...rest}
              onCloseRequest={() => this.onCloseAlertClick(alert)}
              onClose={() => this.onCloseAlert(alert)}
            >
              {message}
            </Alert>
          );
        })}
      </Container>
    </div>
  }

  render() {
    return (
      <div>
        {this.mainView()}
      </div>
    );
  }
}