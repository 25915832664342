import React from "react";
import Island, {Content, Header} from "@jetbrains/ring-ui-built/components/island/island";
import Text from "@jetbrains/ring-ui-built/components/text/text"
import {MultipleChoiceFilter} from "./components/multiple-choice-filter";
import Link from "@jetbrains/ring-ui-built/components/link/link";
import {getSpaceRepositoryLink} from "./utils";

export const CouplingTopPanel =
    ({
         projectKey,
         repositoryName,
         selectedCouplingFilter,
         setSelectedCouplingFilter,
         couplingFilterData,
         selectedDependencyFilter,
         setSelectedDependencyFilter,
         dependencyFilterData,
         selectedFrequencyFilter,
         setSelectedFrequencyFilter,
         frequencyFilterData,
         selectedPathFilter,
         setSelectedPathFilter,
         pathFilterData
     }) => {
        return <Island className="coupling-top-panel">
            <Header>
                <Text size={Text.Size.L}>Coupling report for </Text>
                <Link href={getSpaceRepositoryLink(projectKey, repositoryName)} active hover>
                    <Text size={Text.Size.L}>{repositoryName}</Text>
                </Link>
            </Header>
            <Content>
                <div className="coupling-top-panel__description">
                    <Text size={Text.Size.M} info>
                        This metric shows the strength of the relationship between files.
                        The data is based on the grazie-professional repository's last 5,000 commits.
                        Learn more about <Link active hover
                        href="https://youtrack.jetbrains.com/articles/ICTL-A-51/Logical-Coupling-Analysis">Coupling
                        Score</Link>.
                    </Text>
                </div>
                <MultipleChoiceFilter selected={selectedCouplingFilter} setSelected={setSelectedCouplingFilter}
                                      data={couplingFilterData}
                                      innerLabel="Please select coupling score" upperLabel="Coupling score"/>
                <MultipleChoiceFilter selected={selectedDependencyFilter} setSelected={setSelectedDependencyFilter}
                                      data={dependencyFilterData}
                                      innerLabel="Please select type of dependency" upperLabel="Dependency type"/>
                <MultipleChoiceFilter selected={selectedFrequencyFilter} setSelected={setSelectedFrequencyFilter}
                                      data={frequencyFilterData}
                                      innerLabel="Please select change frequency" upperLabel="Change frequency"/>
                <MultipleChoiceFilter selected={selectedPathFilter} setSelected={setSelectedPathFilter}
                                      data={pathFilterData}
                                      innerLabel="Please select location" upperLabel="Location"/>
            </Content>
        </Island>
    }