/** @format */

import React from "react";
import LegendColor from "./LegendColor";
import StatsPane from "./StatsPane";
import {addMargin} from "./Navigator";
import {useParams} from "react-router-dom";

function RightColumn({statsData}) {
  const {projectKey, repositoryName} = useParams()

  return (
    <div className="col p-1">
      {addMargin(
        <StatsPane data={statsData}/>
      )}
      <center>
        <LegendColor
      summary={"Colors can be picked by clicking on the squares below"}></LegendColor>
      </center>
    </div>
  );
}

export default RightColumn;
