import axios from "axios";
import {NEED_LOAD, READY, RUNNING} from "./components/SpaceProjectView";
import alert from "@jetbrains/ring-ui-built/components/alert-service/alert-service";

const debug = false

export function sendRequest(apiPath, params, callback, trackProgress, data = {}, method = "get", handleError) {
    return axios.request({
        method: method,
        data: data,
        url: `/api/${apiPath}`,
        params: params,
        onDownloadProgress: p => {
            if (trackProgress) {
                const progress = p.loaded / p.total;
                trackProgress(progress)
            }
        }
    }).then(response => {
        callback(response.data)
    })
        .catch((error) => {
            if (handleError !== undefined) {
                handleError(error)
            }

            if (error.response?.status) {
                alert.error(`${error.response.status}: ${error.response.data.slice(0, 35)}. ${error.response.headers['x-request-id']}`);
            } else {
                console.error(error);
            }
        })
}

export async function whoAmI() {
    return axios.get('/api/whoami', { withCredentials: true });
}

export function requestReadyProjects(callback, trackProgress) {
    if (!debug) {
        sendRequest("workspace/space/projects", {}, callback, trackProgress)
    } else {
        callback(generateSpaceProject(10, ""))
    }
}

export function requestReadyProject(projectKey, repositoryName, reportType, callback, trackProgress) {
  sendRequest("report/ready", {projectKey: projectKey, repositoryName: repositoryName, reportType: reportType}, callback, trackProgress)
}

export function requestLog(params, callback) {
    return sendRequest("workspace/space/report/log", params, callback)
}

export function requestAllReports(callback) {
    sendRequest("workspace/space/reports/all", {}, callback)
}

export function requestProject(projectInfo, callback, trackProgress, handleError) {
    if (!debug) {
        sendRequest(`report/${projectInfo.reportType.replace('_', '-').toLowerCase()}/content`, projectInfo, callback, trackProgress, {}, "get", handleError)
    }
}

export function requestProjects(term, callback, trackProgress) {
    if (!debug) {
        sendRequest("workspace/space/projects/search", {term: term}, callback, trackProgress);
    } else {
        callback(generateSpaceProject(3, term))
    }
}

export function requestStatusOfTasks(callback, trackProgress) {
    if (!debug) {
        sendRequest("workspace/space/events", {}, callback, trackProgress)
    }
}

export function rerunTask(projectKey, repositoryName, reportType, reportId, callback, handleError) {
  sendRequest(`report/${reportType.replace('_', '-').toLowerCase()}/rerun`, {}, callback, undefined, {
    projectKey: projectKey,
    repositoryName: repositoryName,
    reportId: reportId,
  }, "post", handleError);
}

export function scheduleTask(projectKey, repositoryName, reportType, callback, handleError) {
    if (!debug) {
        sendRequest(`report/${reportType.replace('_', '-').toLowerCase()}/submit`, {}, callback, undefined, {
            projectKey: projectKey,
            repositoryName: repositoryName,
        }, "post", handleError);
    } else {
        const time = Math.floor(Math.random() * 3)
        sleep(time * 1000).then(r =>
            callback()
        )
    }
}

export function loadChart(projectKey, repositoryName, callback) {
    sendRequest("report/chart/load", {
        repositoryName: repositoryName,
        projectKey: projectKey,
    }, callback)
}

export function saveChart(projectKey, repositoryName, chart) {
    sendRequest("report/chart/save", {}, undefined, undefined, {
        projectKey: projectKey,
        repositoryName: repositoryName,
        chart: JSON.stringify(chart),
    }, "post");
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function generateSpaceProject(n, prefix) {
    const results = []
    for (let id = 0; id < n; id++) {
        const repositories = []
        const project = `${prefix}${id}`
        const size = Math.floor(Math.random() * 8)
        for (let y = 0; y < size; y++) {
            const i = id + y;
            const typeNumber = Math.floor(Math.random() * 3)
            let status
            switch (typeNumber) {
                case 0:
                    status = RUNNING
                    break
                case 1:
                    status = READY
                    break;
                default:
                    status = NEED_LOAD
            }

            repositories.push(
                {
                    id: `Repository ${i} of project ${project}`,
                    name: `Repository name ${i}`,
                    description: `Repository description ${i}`,
                    status: status
                }
            )
        }

        results.push(
            {
                info: {
                    id: project,
                    key: `Key ${project}`,
                    name: `Name ${project}`,
                    tags: ["tag 1", "tag 2"],
                    description: `Here is some description of project ${project}`
                },
                repositories: repositories
            }
        );
    }

    return results;
}

export const homeViewStyle = {
    padding: "32px 16px",
    width: "70%",
    margin: "auto",
    minHeight: "75vh"
}