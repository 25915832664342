import React, {useEffect} from "react";
import {Col, Grid, Row} from "@jetbrains/ring-ui-built/components/grid/grid";
import {requestAllReports} from "../Util";
import task from '@jetbrains/icons/task';
import ErrorMessage from "@jetbrains/ring-ui-built/components/error-message/error-message";
import {Table} from "@jetbrains/ring-ui-built/components/table/table";
import Pager from "@jetbrains/ring-ui-built/components/pager/pager";
import Selection from "@jetbrains/ring-ui-built/components/table/selection";
import Link from "@jetbrains/ring-ui-built/components/link/link";
import LoaderInline from "@jetbrains/ring-ui-built/components/loader-inline/loader-inline";
import Icon from "@jetbrains/ring-ui-built/components/icon";
import ok from '@jetbrains/icons/ok';
import exception from '@jetbrains/icons/exception';
import projectIcon from "@jetbrains/icons/project-14px";
import averageIcon from "@jetbrains/icons/average";
import userIcon from "@jetbrains/icons/user";
import puzzleIcon from "@jetbrains/icons/puzzle";
import Badge from "@jetbrains/ring-ui-built/components/badge/badge";
import {ReportType} from "./VisualizationView";
import {ReportStatus} from "./SpaceProjectView";
import Loader from "@jetbrains/ring-ui-built/components/loader/loader";

export const ReportsPage = () => {
  const [reports, setReports] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true);

  const updateReportsCallback = (newReports) => {
    if (isLoading) {
      setIsLoading(false);
    }
    setReports(
      newReports.map(report => ({
        ...report,
        formattedStartedAt: formatDateToTeamcityLikeString(report.startedAt),
        formattedFinishedAt: formatDateToTeamcityLikeString(report.finishedAt)
      }))
    );
  }

  useEffect(() => {
    requestAllReports(updateReportsCallback);
    const interval = setInterval(() => {
      requestAllReports(updateReportsCallback);
    }, 10000)
    return () => clearInterval(interval);
  }, [])

  const isEmpty = !isLoading && reports.length === 0;
  return (
    <Grid style={{minHeight: "75vh", maxHeight: "75vh"}}>
      <Row>
        <Col xs>
          {isEmpty ?
            <div style={{height: '60vh'}}>
              <ErrorMessage
                icon={task}
                code={'Empty'}
                message={'there are no reports yet'}
                description={'Go to home page and submit new task!'}
              />
            </div>
            : <ReportTable reportRows={reports} isLoading={isLoading}/>
          }
        </Col>
      </Row>
    </Grid>
  )
}

const formatDateToTeamcityLikeString = (time) => {
  if (time) {
    return new Date(Date.parse(time)).toLocaleTimeString(
      navigator.language, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        time: 'numeric',
      }
    )
  }

  return ""
}

/**
 * reportRows: [{
 *  id: Long,
 *  key: String,
 *  repositoryName: String,
 *  type: String,
 *  fullName: String,
 *  spaceLink: String,
 *  status: String,
 *  startedBy: String
 *  startedAt: String
 *  finishedAt: String
 *  formattedStartedAt: String
 *  formattedFinishedAt: String
 * }]
 */
const ReportTable = ({reportRows, isLoading}) => {
  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(12)

  const [visibleRows, setVisibleRows] = React.useState([]);
  useEffect(() => {
    setVisibleRows(reportRows.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize));
  }, [reportRows]);

  const handlePageChange = React.useCallback((newPage) => {
    setPage(newPage)
    setVisibleRows(reportRows.slice((newPage - 1) * pageSize, (newPage - 1) * pageSize + pageSize))
  }, []);

  return (
    <div style={{overflowX: "auto"}}>
      <Table
        data={visibleRows}
        selectable={false}
        caption={'All reports'}
        isItemCollapsible={() => false}
        isItemCollapsed={() => false}
        selection={new Selection({})}
        onSelect={() => {}}
        renderEmpty={() => <TableLoader/>}
        draggable={false}
        stickyHeader={true}
        columns={[
          {
            id: "fullName",
            title: "Name",
            sortable: false,
            getValue: (item, _) => item.status !== ReportStatus.running ?
              <Link href={`/vis/${item.key}/${item.repositoryName}/${item.type}`}>{item.fullName}</Link> : item.fullName,
            headerClassName: "report-fullname-column",
          },
          {
            id: "type",
            title: "Type",
            sortable: false,
            getValue: (item, _) => {
              let icon = "";
              let text = ""
              const type = item.type
              if (type === ReportType.bf) {
                icon = projectIcon
                text = "bus-factor"
              } else if (type === ReportType.complexity) {
                icon = averageIcon
                text = "complexity"
              } else if (type === ReportType.contributors) {
                icon = userIcon
                text = "contributors"
              } else if (type === ReportType.coupling) {
                icon = puzzleIcon
                text = "coupling"
              }
              return <Badge><Icon glyph={icon}/> {text}</Badge>
            },
            className: "type-column",
            headerClassName: "type-column",
          },
          {
            id: "status",
            title: "Status",
            sortable: false,
            getValue: (item, _) => {
              const status = item.status;
              let color = "";
              if (status === ReportStatus.failed) {
                color = "rgb(169, 15, 26)";
                return <div style={{color: color}}><Icon glyph={exception}/> Failed</div>
              } else if (status === ReportStatus.running) {
                color = "rgb(12, 117, 35)";
                return <div style={{color: color}}><LoaderInline/> Running</div>
              } else if (status === ReportStatus.done) {
                color = "rgb(12, 117, 35)";
                return <div style={{color: color}}><Icon glyph={ok}/> Success</div>
              }
              return <div style={{color: color}}>{item.status}</div>
            },
            className: "status-column",
            headerClassName: "status-column",
          },
          {
            id: "startedBy",
            title: "Started By",
            sortable: false,
          },
          {
            id: "formattedStartedAt",
            title: "Started",
            sortable: false,
            className: "date-column",
            headerClassName: "date-column",
          },
          {
            id: "formattedFinishedAt",
            title: "Finished",
            sortable: false,
            className: "date-column",
            headerClassName: "date-column",
          },
          {
            id: "elapsed",
            title: "Duration, sec",
            sortable: false,
            getValue: (item, _) => {
              const finishedAt = item.finishedAt ? item.finishedAt : new Date()
              return finishedAt && item.startedAt ?
                <div>{Math.round((Date.parse(finishedAt) - Date.parse(item.startedAt)) / 1000)}</div> : ""
            },
          },
          {
            id: "Log",
            title: "Log",
            sortable: false,
            getValue: (item, _) => <Link href={`/log/${item.key}/${item.repositoryName}/${item.type}`}>Show log</Link>,
            className: "log-column",
            headerClassName: "log-column",
          },
          {
            id: "open",
            title: "Open Repository",
            sortable: false,
            className: "repo-column",
            getValue: (report, _) => <Link href={report.spaceLink}>Open on Space</Link>,
          },
        ]}
      />
      <Grid>
        <Row>
          <Col>
            <Pager
              total={reportRows.length}
              pageSize={pageSize}
              currentPage={page}
              disablePageSizeSelector
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

const TableLoader = () => {
  return (
    <div style={{
      paddingTop: '25vh',
      paddingBottom: '25vh',
    }}>
      <Loader
        message={"Loading"}
        style={{paddingBottom: "16px"}}
      />
    </div>
  )
}