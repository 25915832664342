import {React} from "react";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import Island from "@jetbrains/ring-ui-built/components/island/island";
import {Content} from "@jetbrains/ring-ui-built/components/island/island";
import Header from "@jetbrains/ring-ui-built/components/island/header";
import Markdown from "@jetbrains/ring-ui-built/components/markdown/markdown";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function ContributorsCountChart({values, periods}) {
  const data = {
    datasets: [
      {
        label: 'Count',
        data: values,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3
      },
    ],
    labels: periods
  };
  const options = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      title: {
        display: false,
        text: 'Contributors Count',
      },
    },
    scales: {
      y: {
        bounds: 'data',
        sampleSize: 10,
        autoSkip: false
      },
      x: {
        autoSkip: false
      }
    }
  };


  return <Island>
    <Header border>
      Contributors count
    </Header>
    <Content>
      <div style={{marginBottom: "15px"}}>
        <Markdown inline={false}>{`
            This chart represents the counts of unique contributors per weekly period.
            `}</Markdown>
      </div>
      <Island><Content>
        <Line options={options} data={data}/>
      </Content></Island>
    </Content>
  </Island>
}