import React, {useContext} from "react";
import Header, {TrayIcon} from "@jetbrains/ring-ui-built/components/header/header";
import Logo from "@jetbrains/ring-ui-built/components/header/logo";
import Theme from "@jetbrains/ring-ui-built/components/global/theme";
import Link from "@jetbrains/ring-ui-built/components/link/link";
import Tray from "@jetbrains/ring-ui-built/components/header/tray";
import helpIcon from '@jetbrains/icons/help-20px';
import userIcon from '@jetbrains/icons/user-20px';
import {ReactComponent as ICTLLogo} from "./ictl.svg";
import {AuthContext} from "../auth/AuthContext";
import Avatar, {Size} from "@jetbrains/ring-ui-built/components/avatar/avatar";

export const ApplicationBar = () => {
    const { user, loading } = useContext(AuthContext);

    return (
        <Header theme={Theme.DARK}>
          <Logo glyph={ICTLLogo} size={Logo.Size.Size48}/>
          <Link href="/" style={{color: "whitesmoke"}}>Yaga</Link>
          <Link href="/reports" style={{color: "whitesmoke"}}>Reports 🚀</Link>
          <Tray>
              <TrayIcon href="/about" title="Help" icon={helpIcon}/>
              {loading ?
                  <TrayIcon href="/login" title="Login" icon={userIcon}/>
                  : <Avatar size={Size.Size32} username={user} />}
          </Tray>
        </Header>
    );
}