import {DEPENDENCY_TAG_VALUES, TAG_TYPES} from "./const";

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const getTagColorByTagTypeAndValue = ({tagType, tagValue}) => {
  return {
    backgroundColor: "rgb(224, 225, 230)",
    textColor: "rgb(39, 40, 46)"
  }
}

export const getCouplingLevelFromPair = (pair) => {
  const couplingTag = pair.tags.filter((tag) => tag.tagType === TAG_TYPES.COUPLING)

  if (couplingTag !== null && couplingTag.length !== 0) {
    return couplingTag[0].tagValue
  }

  return null
}

export const getSpaceRepositoryLinkToFile = (projectKey, repositoryName, filename) =>
  `https://jetbrains.team/p/${projectKey}/repositories/${repositoryName}/files/${filename}`


const commonPrefix = (words) => {
  // check border cases size 1 array and empty first word)
  if (!words[0] || words.length == 1) return words[0] || "";
  let i = 0;
  // while all words have the same character at position i, increment i
  while (words[0][i] && words.every(w => w[i] === words[0][i]))
    i++;

  // prefix is the substring from the beginning to the last successfully checked i
  return words[0].substr(0, i);
}

export const commonPath = (filename1, filename2) => {
  // both files starts from "./" dir
  const prefix = commonPrefix([filename1, filename2])

  if (prefix.length === 0) {
    return ""
  }

  if (prefix[prefix.length - 1] === "/") {
    return prefix.substr(0, prefix.length - 1)
  }

  const lastIndexOfSlash = prefix.split("").findLastIndex((symbol) => symbol === "/")

  if (lastIndexOfSlash <= 0) {
    return ""
  }

  return prefix.substr(0, lastIndexOfSlash)
}

export const getInsightFromPair = (pair) => {
  const tagDependencyValue = pair.tags.filter((tag) => tag.tagType === TAG_TYPES.DEPENDENCY)

  if (tagDependencyValue.length !== 1) {
    return "Changes in this file are unlikely to impact the other. You can proceed with modifications independently."
  }

  if (tagDependencyValue[0].tagValue === DEPENDENCY_TAG_VALUES.CODEPENDENT) {
    return "When modifying file A or B, check another file for necessary updates."
  }

  if (pair.changeProbabilityOfFile2IfFile1Changes > pair.changeProbabilityOfFile1IfFile2Changes) {
    return "When modifying file A, check file B for necessary updates."
  }

  return "When modifying file B, check file A for necessary updates."
}

export const getConclusionFromPair = (pair) => {
  if (pair.logicalCouplingScore >= 0.9) {
    return "It is highly recommended to refactor the code, if possible, in order to reduce coupling and increase future maintainability."
  }

  if (pair.logicalCouplingScore >= 0.8) {
    return "Consider refactoring, if possible, in order to reduce coupling and increase future maintainability."
  }

  if (pair.logicalCouplingScore >= 0.7) {
    return "Including the pair of files in the documentation is highly recommended"
  }

  if (pair.logicalCouplingScore >= 0.6) {
    return "Consider including the pair of files in the documentation."
  }

  return "Regularly verify that no new dependencies have inadvertently been introduced."
}
