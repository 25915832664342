import React from "react";
import Markdown from "@jetbrains/ring-ui-built/components/markdown/markdown";
import {homeViewStyle} from "../Util";
import MarkdownIt from "markdown-it";
import {Grid} from "@jetbrains/ring-ui-built/components/grid/grid";
import Row from "@jetbrains/ring-ui-built/components/grid/row";
import Col from "@jetbrains/ring-ui-built/components/grid/col";


export const AboutView = () => {
  return (
      <div style={homeViewStyle}>
        <Grid>
          <Row start="xs">
            <Col xs={6}>
              <Markdown>
                <div dangerouslySetInnerHTML={{
                  __html: aboutCoupling()
                }}/>
              </Markdown>
            </Col>
            <Col xs={6}>
              <div>
                <Markdown>
                  <div dangerouslySetInnerHTML={{
                    __html: aboutBusFactor()
                  }}/>
                </Markdown>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
  )
}

const markdownIt = new MarkdownIt('commonmark', {
  html: false,
});

const aboutBusFactor = () => markdownIt.render(
    `
  # Bus Factor 
  **def**: Bus factor is a metric that denotes the resilience of the project to sudden engineer turnover. 
  Bus factor is the minimal number of engineers that have to be \"hit by a bus\", or leave the project, 
  for it to stall.
  
  By analyzing the history of code contributions, we can identify the files and subsystems at risk of 
  stalling in the event of developer turnover. We believe that bus factor analysis is a good starting point to work on 
  enabling the collaboration tools to use version control history data to assist with organization of teamwork.
  The goal of this project at this stage is to evaluate the viability of bus factor analysis as a user-facing feature in one of JetBrains projects.
  
  ## Demo
  This demo service is meant to enable everyone at JetBrains to see the results of bus factor analysis for every repository hosted on Space.
  
  How it works:
  1. Choose a project from Space and select a repository.
  2. Click on the \`bus-factor\` button.
  3. The service loads the repository and calculates the logical coupling report.
  4. The report becomes available for viewing.
  
  ## Algorithm
  You can get more information about bus factor calculation in our paper [Bus factor in practice](https://arxiv.org/pdf/2202.01523.pdf#:~:text=Bus%20factor%20is%20a%20metric,ies%20consider%20its%20general%20relevance.)
  `
)

const aboutCoupling = () => markdownIt.render(
    `
  # Logical Coupling
  **def**: Logical coupling is the relationship between two or more software artifacts that frequently change together during the evolution of a software system. High logical coupling may signify a suboptimal architecture.
    
  While there are different static analysis techniques to reveal dependencies between software components, we are curious if git data can reveal hidden dependencies!
  
  We focus on two types of logical coupling:
  1. **One-way-dependent:** the dependency goes one way (file B often changes with file A, but not necessarily the other way around)
  2. **Codependent:** the changes frequently occur together, indicating a more symmetrical relationship between files. 
    
  ## Demo
  This demo service is meant to enable everyone at JetBrains to see the results of logical coupling analysis for every repository hosted on Space.
  
  How it works:
  1. Choose a project from Space and select a repository.
  2. Click on the \`coupling\` button.
  3. The service loads the repository and calculates the logical coupling report.
  4. The report becomes available for viewing.
  
  ## Algorithm
  For more detailed information about the logical coupling calculation, refer to [this document](https://youtrack.jetbrains.com/articles/ICTL-A-51/Logical-Coupling-Analysis).
  `
)
