import React from "react";
import Footer from "@jetbrains/ring-ui-built/components/footer/footer";

export const ApplicationFooter = () => {

  return (
    <Footer
      className="footer"
      left={[
        [
          {
            url: 'https://jetbrains.team/p/pan/deployments/targets/bus-factor-app/deployments',
            label: 'bus-factor-app'
          },
          ' by ICTL'
        ],
      ]}
      center={[
        {
          url: 'https://forms.gle/4MPZqbqhSaasVGez8',
          label: 'Feedback',
          title: 'please, fill the form',
          target: '_blank'
        }
      ]}
      right={[
        {
          url: 'https://jetbrains.slack.com/archives/C07NW6JJWJJ',
          label: '#yaga-support'
        }
      ]}
    />
  );
}