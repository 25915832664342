import React, { createContext, useState, useEffect } from 'react';
import {whoAmI} from "../Util";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUser = () => {
            whoAmI()
                .then((response) => {
                    if (response.status === 200) {
                        setUser(response.data.username);
                    } else {
                        console.error('Failed to fetch user info', response.status, response);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        if (window.location.hostname === 'localhost') {
                            window.location = "http://" + window.location.hostname + ":8080/login";
                        } else {
                            window.location.href = '/login';
                        }
                    }
                    console.error('Error fetching user info:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        checkUser();
    }, []);

    return (
        <AuthContext.Provider value={{ user, loading }}>
            {children}
        </AuthContext.Provider>
    );
};