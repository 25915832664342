import Visualization from "../visualization/components/Visualization";
import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {LoaderWithProgress} from "./LoaderWithProgress";
import {useDispatch} from "react-redux";
import {requestProject, requestReadyProject, scheduleTask} from "../Util";
import {setNewTree} from "../visualization/reducers/treemapSlice";
import {ComplexityChart} from "../visualization/components/ComplexityChart";
import frownIcon from "@jetbrains/icons/frown";
import ErrorMessage from "@jetbrains/ring-ui-built/components/error-message/error-message";
import {ContributorsCountChart} from "../visualization/components/ContributorsCountChart";
import {CouplingPage} from "../pages/coupling-page";

const LoadState = {
  LOADING: "loading",
  CALCULATING: "calculating",
  DONE: "done",
  FAILED: "failed"
}

function VisualizationView() {
  const {reportType, projectKey, repositoryName} = useParams()
  const [progress, setProgress] = useState(0.0)
  const [loadState, setLoadState] = useState(LoadState.LOADING)
  const [initialized, setInitialized] = useState(false)
  const [couplingData, setCouplingData] = useState({
    changedPairs: []
  })
  const [complexityData, setComplexityData] = useState({
    labels: [],
    values: [],
  })
  const [contributorsCountData, setContributorsCountData] = useState({
    values: [],
    periods: []
  })
  const reportTypeUpper = reportType.toUpperCase()
  const dispatch = useDispatch()


  const requestData = (handleError) => {
    requestProject(
      {
        projectKey: projectKey,
        repositoryName: repositoryName,
        reportType: reportTypeUpper,
      }, (response) => {
        setLoadState(LoadState.DONE)
        if (reportTypeUpper === ReportType.bf) {
          dispatch(setNewTree(response))
        } else if (reportTypeUpper === ReportType.complexity) {
          setComplexityData(response);
        } else if (reportTypeUpper === ReportType.contributors) {
          setContributorsCountData(response)
        } else if (reportTypeUpper === ReportType.coupling) {
          setCouplingData(response)
        }
        setInitialized(true)

        if (interval.current !== undefined) {
          clearInterval(interval.current);
        }
      },
      setProgress,
      handleError
    )
  }

  const interval = useRef();
  useEffect(() => {
    function setTimerRequestReadyProject() {
      interval.current = setInterval(() => {
        setLoadState(LoadState.CALCULATING)
        requestReadyProject(projectKey, repositoryName, reportType, (isReady) => {
          if (isReady) {
            requestData()
          }
        })
      }, 3000);
    }

    requestData((error) => {
      scheduleTask(projectKey, repositoryName, reportType, (responce) => {
        setTimerRequestReadyProject()
      })
    })

  }, []);


  const view = () => {
    if (!initialized) {
      const metaInfo = `repository "${repositoryName}" of project "${projectKey}"`
      // fixme: progress === 1 not working, there is no msg change
      let msg
      switch (loadState) {
        case LoadState.DONE:
          msg = `Data is loaded. Initializing visualization for ${metaInfo}`
          break
        case LoadState.LOADING:
          msg = `Loading visualization data for ${metaInfo}`
          break
        case LoadState.CALCULATING:
          msg = `Waiting calculation for ${metaInfo}.`
          break
        default:
          msg = "Unhandled case. Please report."
      }
      return <LoaderWithProgress
        message={msg}
        loadProgress={progress * 0.9}
      />
    }

    if (reportTypeUpper === ReportType.bf) {
      return <Visualization/>
    } else if (reportTypeUpper === ReportType.complexity) {
      return <div style={chartStyle}>
        <ComplexityChart values={complexityData.values} labels={complexityData.labels}/>
      </div>
    } else if (reportTypeUpper === ReportType.contributors) {
      return <div style={chartStyle}>
        <ContributorsCountChart values={contributorsCountData.values} periods={contributorsCountData.periods}/>
      </div>
    } else if (reportTypeUpper === ReportType.coupling) {
      return <div style={chartStyle}>
        <CouplingPage changedPairs={couplingData.changedPairs} projectKey={projectKey} repositoryName={repositoryName}></CouplingPage>
      </div>
    }
    return (
      <div style={{height: '60vh'}}>
        <ErrorMessage
          icon={frownIcon}
          code={'Oops'}
          message={'something went wrong'}
          description={'Report type is not supported'}
        />
      </div>
    );
  }

  return (
    <>
      {view()}
    </>
  )
}

export const ReportType = {
  bf: "BUS_FACTOR",
  complexity: "COMPLEXITY_CHANGE",
  contributors: "CONTRIBUTORS_COUNT",
  coupling: "COUPLING",
}

export default VisualizationView

export const chartStyle = {
  padding: "32px 16px",
  width: "95%",
  margin: "auto",
  minHeight: "75vh"
}