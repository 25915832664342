import React from "react";

import {HomeView} from "./components/HomeView";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./visualization/store";
import './visualization/i18n';
import {AboutView} from "./components/AboutView";
import VisualizationView from "./components/VisualizationView";
import {ReportsPage} from "./components/ReportsPage";
import {ApplicationBar} from "./components/ApplicationBar";
import {ApplicationFooter} from "./components/ApplicationFooter";
import {LogPage} from "./components/LogPage";
import {AuthProvider} from "./auth/AuthContext";
import {ErrorBoundary} from "./components/ErrorBoundary"

export default function App() {
    return (
        <React.StrictMode>
            <AuthProvider>
                <BrowserRouter>
                    <Provider store={store}>
                        <ApplicationBar/>
                        <ErrorBoundary>
                            <Routes>
                                <Route path={"/"} element={<HomeView/>}/>
                                <Route path={"/about"} element={<AboutView/>}/>
                                <Route path={"/vis/:projectKey/:repositoryName/:reportType"}
                                       element={<VisualizationView/>}/>
                                <Route path={"/log/:projectKey/:repositoryName/:reportType"} element={<LogPage/>}/>
                                <Route path={"/reports"} element={<ReportsPage/>}/>
                            </Routes>
                        </ErrorBoundary>
                        <ApplicationFooter/>
                    </Provider>
                </BrowserRouter>
            </AuthProvider>
        </React.StrictMode>
    );
}
