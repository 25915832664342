import React from "react";

export const LowBadge = () => {
  return (
    <svg width="78" height="20" viewBox="0 0 78 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8_6)">
        <rect width="78" height="20" rx="3" fill="#C5E5CC"/>
        <path
          d="M28.108 15V5.51H29.512V13.713H33.659V15H28.108ZM38.6025 15.117C38.0132 15.117 37.5019 15.0043 37.0685 14.779C36.6352 14.5537 36.3015 14.233 36.0675 13.817C35.8335 13.401 35.7165 12.9113 35.7165 12.348V10.502C35.7165 9.93 35.8335 9.44033 36.0675 9.033C36.3015 8.617 36.6352 8.29633 37.0685 8.071C37.5019 7.84567 38.0132 7.733 38.6025 7.733C39.1919 7.733 39.7032 7.84567 40.1365 8.071C40.5699 8.29633 40.9035 8.617 41.1375 9.033C41.3715 9.44033 41.4885 9.93 41.4885 10.502V12.348C41.4885 12.9113 41.3715 13.401 41.1375 13.817C40.9035 14.233 40.5699 14.5537 40.1365 14.779C39.7032 15.0043 39.1919 15.117 38.6025 15.117ZM38.6025 13.882C39.0792 13.882 39.4475 13.752 39.7075 13.492C39.9675 13.2233 40.0975 12.842 40.0975 12.348V10.502C40.0975 9.99933 39.9675 9.618 39.7075 9.358C39.4475 9.098 39.0792 8.968 38.6025 8.968C38.1345 8.968 37.7662 9.098 37.4975 9.358C37.2375 9.618 37.1075 9.99933 37.1075 10.502V12.348C37.1075 12.842 37.2375 13.2233 37.4975 13.492C37.7662 13.752 38.1345 13.882 38.6025 13.882ZM44.1831 15L43.0781 7.85H44.2611L44.9241 12.569C44.9588 12.7857 44.9891 13.0283 45.0151 13.297C45.0498 13.557 45.0758 13.7737 45.0931 13.947C45.1105 13.7737 45.1365 13.557 45.1711 13.297C45.2145 13.037 45.2535 12.7943 45.2881 12.569L46.0291 7.85H47.2121L47.9401 12.569C47.9748 12.7943 48.0095 13.0413 48.0441 13.31C48.0875 13.57 48.1178 13.7867 48.1351 13.96C48.1525 13.778 48.1785 13.557 48.2131 13.297C48.2565 13.0283 48.2955 12.7857 48.3301 12.569L49.0061 7.85H50.1501L49.0061 15H47.5241L46.8221 10.333C46.7875 10.0903 46.7485 9.83467 46.7051 9.566C46.6618 9.28867 46.6315 9.06767 46.6141 8.903C46.5881 9.06767 46.5535 9.28867 46.5101 9.566C46.4755 9.83467 46.4408 10.0903 46.4061 10.333L45.6781 15H44.1831Z"
          fill="#1F7536"/>
      </g>
      <defs>
        <clipPath id="clip0_8_6">
          <rect width="78" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}