import React from "react";
import {COUPLING_TAG_VALUES} from "../../const";
import {CriticalBadge} from "./critical-badge";
import {MediumBadge} from "./medium-badge";
import {HighBadge} from "./high-badge";
import {LowBadge} from "./low-badge";
import {MinorBadge} from "./minor-badge";

export const CouplingBadge = ({tagValue}) => {
  if (tagValue === COUPLING_TAG_VALUES.CRITICAL) {
    return <CriticalBadge/>
  }
  if (tagValue === COUPLING_TAG_VALUES.HIGH) {
    return <HighBadge/>
  }
  if (tagValue === COUPLING_TAG_VALUES.MEDIUM) {
    return <MediumBadge/>
  }

  if (tagValue === COUPLING_TAG_VALUES.LOW) {
    return <LowBadge/>
  }

  return <MinorBadge/>
}