/** @format */

import { treemapBinary, treemapSquarify } from "d3";

export const layoutAlgorithmsMap = {
  "binary": treemapBinary,
  "squarify": treemapSquarify,
}

export const squarify = treemapSquarify;
export const binary = treemapBinary;

export const layoutAlgorithmSelectData = Object.keys(layoutAlgorithmsMap).map((element, index) => {
  return {
    label: element,
    key: element,
  };
})
